<template>
    <q-dialog value persistent @hide="onHide">
        <q-card>
            <q-card-section class="row items-center">
                <q-avatar icon="error_outline" color="warning" text-color="white" />
                <span class="q-ml-sm">{{ $t('app.subscriptions.cancel_confirmation_message') }}</span>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn flat :label="$t('generals.close')" color="primary" v-close-popup @click="subscription = null" />
                <q-btn flat :label="$t('app.subscriptions.cancel')" color="negative" v-close-popup @click="cancelSubscription"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import Subscription from 'src/store/models/Subscription'
export default {
  name: 'CancelSubscriptionDialog',
  props: ['subscription', 'onHide'],
  methods: {
    cancelSubscription () {
      Subscription.$cancelSubscription(this.subscription)
        .then(() => {
          this.$q.dialog({
            color: 'positive',
            title: this.$t('app.subscriptions.cancel_success'),
            message: this.$t('app.subscriptions.cancel_success_message')
          })
        })
    }
  }
}
</script>
