<template>
    <q-dialog value persistent ref="invoiceDataDialog" @hide="onHide">
        <q-card style="min-width: 670px; max-width: 80vw">
            <q-tabs
                v-model="tab"
                class="text-grey-7 bg-grey-3"
                active-color="primary"
                indicator-color="primary"
                align="justify"
                narrow-indicator
            >
                <q-tab name="invoice_data" :label="$t('app.subscriptions.profile_data')" />
                <q-tab name="invoices" :label="$t('app.subscriptions.invoices')" />
            </q-tabs>

            <q-separator />

            <q-tab-panels v-model="tab" animated keep-alive>
                <q-tab-panel name="invoice_data" style="max-height: 75vh">
                    <q-form ref="invoiceDataForm" greedy>
                      <q-card-section class="q-pb-none">
                        <div class="text-h6 text-accent text-center">{{ $t('app.subscriptions.login_data') }}</div>
                      </q-card-section>
                      <q-card-section>
                          <div class="row q-col-gutter-md items-center">
                              <div class="col">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('generals.full_name')"
                                      disable
                                      v-model="user.name"
                                  >
                                  </q-input>
                              </div>
                              <div class="col">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.email')"
                                      disable
                                      v-model="user.email"
                                  >
                                  </q-input>
                              </div>
                              <div class="col col-md-3">
                                  <q-btn to="/auth/password-reset" color="primary" size="sm" :label="$t('auth.reset_password')"></q-btn>
                              </div>
                          </div>
                      </q-card-section>

                      <q-card-section class="q-pb-none">
                        <div class="text-h6 text-accent text-center">{{ $t('app.subscriptions.company_logo') }}</div>
                      </q-card-section>
                      <q-card-section>
                        <div v-if="me.company.image" class="column justify-center items-center">
                          <div class="col flex justify-center">
                            <img :src="me.company.image_url" style="max-height: 80px"/>
                          </div>
                          <div class="col-auto q-pt-sm">
                            <q-btn
                              size="sm"
                              color="primary"
                              @click="deleteCompanyLogo()"
                            >
                                Elimina logo
                            </q-btn>
                          </div>
                        </div>
                        <div class="row items-end q-gutter-md">
                          <div class="col">
                            <q-file v-model="user.company.image" label="Carica logo aziendale" accept=".jpg, image/*">
                              <template v-slot:prepend>
                                <q-icon name="cloud_upload" @click.stop />
                              </template>
                            </q-file>
                          </div>
                          <div class="col-auto">
                            <q-btn
                              size="sm"
                              color="primary"
                              @click="updateCompanyLogo()"
                            >
                                Salva logo
                            </q-btn>
                          </div>
                        </div>
                      </q-card-section>

                      <q-card-section class="q-pb-none">
                          <div class="text-h6 text-accent text-center">{{ $t('app.subscriptions.invoice_data') }}</div>
                      </q-card-section>
                      <q-card-section>
                          <div class="row q-col-gutter-md">
                              <div class="col">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('generals.company')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('generals.company') })]"
                                      v-model="user.company.name"
                                  >
                                  </q-input>
                              </div>
                          </div>
                          <div class="row q-col-gutter-md">
                              <div class="col col-md-8">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.address')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.address') })]"
                                      v-model="user.company.address"
                                  >
                                  </q-input>
                              </div>
                              <div class="col col-md-4">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.zip_code')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.zip_code') })]"
                                      v-model="user.company.zip_code"
                                  >
                                  </q-input>
                              </div>
                          </div>
                          <div class="row q-col-gutter-md">
                              <div class="col col-md-4">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.city')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.city') })]"
                                      v-model="user.company.city"
                                  >
                                  </q-input>
                              </div>
                              <div class="col col-md-4">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.state')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.state') })]"
                                      v-model="user.company.state"
                                  >
                                  </q-input>
                              </div>
                              <div class="col col-md-4">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.country')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.country') })]"
                                      v-model="user.company.country"
                                  >
                                  </q-input>
                              </div>
                          </div>
                          <div class="row q-col-gutter-md">
                              <div class="col">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.vat_id')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.vat_id') })]"
                                      v-model="user.company.vat_id"
                                  >
                                  </q-input>
                              </div>
                              <div class="col">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.taxcode')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.taxcode') })]"
                                      v-model="user.company.taxcode"
                                  >
                                  </q-input>
                              </div>
                          </div>
                          <div class="row q-col-gutter-md">
                              <div class="col">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.sdi')"
                                      mask="XXXXXXX"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.sdi') })]"
                                      v-model="user.company.sdi"
                                  >
                                  </q-input>
                              </div>
                              <div class="col">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.pec')"
                                      :hint="$t('app.subscriptions.pec_hint')"
                                      v-model="user.company.pec"
                                  >
                                  </q-input>
                              </div>
                          </div>
                          <div class="row q-col-gutter-md">
                              <div class="col">
                                  <q-input
                                      color="light-gray"
                                      label-color="primary"
                                      :label="$t('app.subscriptions.invoice_email')"
                                      :rules="[val => !!val || $t('validation.required', { field: $t('app.subscriptions.email') })]"
                                      v-model="user.company.email"
                                  >
                                  </q-input>
                              </div>
                          </div>
                          <div class="row q-col-gutter-md">
                              <div class="col">
                                  <q-checkbox
                                      class="items-start"
                                      dense
                                      label-color="primary"
                                      v-model="user.newsletter"
                                  >
                                      {{ $t('app.subscriptions.marketing_emails') }} <br />
                                      {{ $t('app.subscriptions.marketing_emails_nb') }}
                                  </q-checkbox>
                              </div>
                          </div>

                      </q-card-section>

                      <q-separator></q-separator>

                      <q-card-section q-card-section class="q-pb-none" v-if="user && user.company.subscriptions && user.company.subscriptions.length">
                          <div class="text-h6 text-accent text-center">{{ $t('app.subscriptions.active_sub') }}</div>
                      </q-card-section>
                      <q-card-section v-if="user && user.company.subscriptions && user.company.subscriptions.length">
                          <div class="row justify-center">
                              <div class="col-12">
                                  <q-list bordered separator>
                                      <q-item v-for="subscription in user.company.subscriptions" :key="subscription.id">
                                          <q-item-section avatar>
                                              <q-icon name="check" v-if="subscription.stripe_status === 'active'" color="green"></q-icon>
                                              <q-icon name="cancel" v-else-if="subscription.stripe_status === 'canceled'" color="grey"></q-icon>
                                              <q-icon name="cancel" v-else-if="subscription.stripe_status === 'past_due'" color="negative"></q-icon>
                                              <q-icon name="error" v-else color="yellow"></q-icon>
                                          </q-item-section>
                                          <q-item-section>
                                              <q-item-label>{{ $t('app.subscriptions.name') }}</q-item-label>
                                              <q-item-label caption>{{ $t('app.subscriptions.status.title') }}: <span>{{ subscription.statusName }}</span></q-item-label>
                                          </q-item-section>
                                          <q-item-section>
                                              <q-item-label caption v-if="subscription.ends_at">{{ $t('app.subscriptions.expiration_date') }}</q-item-label>
                                              <q-item-label caption v-else>{{ $t('app.subscriptions.renewal_date') }}</q-item-label>
                                              <q-item-label>{{ subscription.formattedPeriodEnd }}</q-item-label>
                                          </q-item-section>
                                          <q-item-section side v-if="subscription.stripe_status !== 'canceled'">
                                              <q-btn flat icon="more_vert">
                                                  <q-menu anchor="bottom right" self="top right">
                                                      <q-item clickable @click="showCancelSubscriptionAlert = true; selectedSubscription = subscription">
                                                          <q-item-section color="negative">
                                                              <span class="text-negative">{{ $t('app.subscriptions.cancel') }}</span>
                                                          </q-item-section>
                                                      </q-item>
                                                  </q-menu>
                                              </q-btn>
                                          </q-item-section>
                                      </q-item>
                                  </q-list>
                              </div>
                          </div>
                          <div class="row q-mt-md">
                              <div class="col col-sm-12">
                                  <q-btn
                                      size="sm"
                                      color="primary"
                                      @click="openCustomerPortal"
                                  >
                                  Gestisci i tuoi dati
                                  </q-btn>
                              </div>
                          </div>
                          <cancel-subscription-dialog v-if="showCancelSubscriptionAlert" :subscription="selectedSubscription" :on-hide="onCancelSubscriptionHide"/>
                      </q-card-section>
                    </q-form>
                </q-tab-panel>

                <q-tab-panel name="invoices">
                    <q-card-section class="q-pb-none">
                        <div class="text-h6 text-primary text-center">{{ $t('app.subscriptions.invoices') }}</div>
                    </q-card-section>
                    <q-card-section>
                        <q-list bordered separator>
                          <q-item v-for="invoice in invoices" :key="invoice.id">
                            <q-item-section>
                              <q-item-label>N. {{ invoice.number }} - <b>{{ invoice.gross_amount.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}</b></q-item-label>
                              <q-item-label caption>{{ invoice.description }}</q-item-label>
                            </q-item-section>

                            <q-item-section side top>
                              <q-item-label caption>{{ formattedDate(invoice.date) }}</q-item-label>
                              <q-btn v-if="invoice.fatture_in_cloud_id" size="xs" icon="email" color="primary" @click="downloadInvoice(invoice)"/>
                            </q-item-section>
                          </q-item>
                          <q-item v-if="invoices.length === 0">
                              <q-item-section>
                                  {{ $t('app.subscriptions.no_invoices') }}
                              </q-item-section>
                          </q-item>
                        </q-list>
                    </q-card-section>
                </q-tab-panel>
            </q-tab-panels>

            <q-separator></q-separator>

            <q-card-section>
                <div class="row">
                    <div class="col col-sm-12 flex justify-between">
                        <q-btn
                            outline
                            color="primary"
                            v-close-popup
                        >
                            Chiudi
                        </q-btn>
                        <q-btn
                            color="warning"
                            @click="updateProfile(true)"
                            :label="$t('app.subscriptions.save_and_purchase')"
                            v-if="subscribing"
                        >
                        </q-btn>
                        <q-btn
                            color="primary"
                            @click="updateProfile(false)"
                        >
                            Salva
                        </q-btn>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import User from 'src/store/models/User'
import Company from 'src/store/models/Company'
import { cloneDeep, debounce } from 'lodash'
import moment from 'moment'
import CancelSubscriptionDialog from './CancelSubscriptionDialog'
import Invoice from 'src/store/models/Invoice'
import { Notify } from 'quasar'
export default {
  name: 'invoice-data-dialog',
  props: ['subscribing', 'onHide', 'successCallback'],
  components: {
    CancelSubscriptionDialog
  },
  data () {
    return {
      user: {
        name: null,
        email: null,
        company: {
          name: null,
          email: null,
          taxcode: null,
          vat_id: null,
          address: null,
          zip_code: null,
          city: null,
          state: null,
          country: null,
          image: null,
          card_last_four: null,
          card_brand: null
        }
      },
      tab: 'invoice_data',
      intent: null,
      cardHolderName: '',
      showCancelSubscriptionAlert: false,
      selectedSubscription: null
    }
  },
  computed: {
    me () {
      return User.me()
    },
    invoices () {
      return Invoice.all()
    }
  },
  watch: {
    'user.newsletter': function (val) {
      this.toggleNewsletter()
    }
  },
  methods: {
    hide () {
      this.$refs.invoiceDataDialog.hide()
    },
    formattedDate (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    onCancelSubscriptionHide () {
      this.showCancelSubscriptionAlert = false
    },
    toggleNewsletter: debounce(function () {
      if (this.user.newsletter === User.me().newsletter) return
      this.$q.loading.show()
      if (this.user.newsletter) {
        User.api().post('/api/newsletter/subscribe')
          .then(() => {
            User.update({
              where: this.user.id,
              data: {
                newsletter: true
              }
            })
            Notify.create(this.$t('newsletter.subscribed'))
            this.user.$updateCustomerlyUser({
              newsletter: true,
              newsletter_subscribed_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              newsletter_unsubscribed_at: null
            })
          })
          .finally(() => {
            this.$q.loading.hide()
          })
      } else {
        User.api().post('/api/newsletter/unsubscribe')
          .then(() => {
            User.update({
              where: this.user.id,
              data: {
                newsletter: false
              }
            })
            Notify.create(this.$t('newsletter.unsubscribed'))
            this.user.$updateCustomerlyUser({
              newsletter: false,
              newsletter_unsubscribed_at: moment().format('YYYY-MM-DD HH:mm:ss')
            })
          })
          .finally(() => {
            this.$q.loading.hide()
          })
      }
    }, 500),
    uploadCompanyLogo () {
      const formData = new FormData()
      formData.append('company_image', this.user.company.image)
      return User.api().post('/api/me', formData)
        .then(({ response }) => {
          Company.update({
            where: this.me.company_id,
            data: response.data.company
          })
        })
    },
    updateCompanyLogo () {
      if (!this.user.company.image) {
        this.$q.notify({
          color: 'warning',
          textColor: 'white',
          icon: 'warning',
          message: 'Carica un\'immagine',
          position: 'top'
        })
        return
      }
      this.$q.loading.show()
      return this.uploadCompanyLogo()
        .then(() => {
          this.$q.notify({
            color: 'positive',
            textColor: 'white',
            icon: 'check',
            message: 'Logo aziendale aggiornato',
            position: 'top'
          })
        })
        .finally(() => {
          this.$q.loading.hide()
        })
    },
    deleteCompanyLogo () {
      this.$q.loading.show()
      return User.api().post('/api/me', { company_image: null })
        .then(({ response }) => {
          Company.update({
            where: this.me.company_id,
            data: response.data.company
          })
          this.$q.notify({
            color: 'positive',
            textColor: 'white',
            icon: 'check',
            message: 'Logo aziendale eliminato',
            position: 'top'
          })
        })
        .finally(() => {
          this.$q.loading.hide()
        })
    },
    updateProfile (subscribe = false) {
      this.$refs.invoiceDataForm.validate().then(async (success) => {
        if (success) {
          if (this.user.company.image) {
            await this.uploadCompanyLogo()
          }
          User.api().put(
            '/api/me',
            this.user
          )
            .then(({ response }) => {
              User.update({
                where: this.me.id,
                data: response.data
              })
              if (!subscribe) {
                this.$q.notify({
                  color: 'positive',
                  textColor: 'white',
                  icon: 'check',
                  message: this.$t('generals.profile_updated'),
                  position: 'top'
                })
              } else {
                this.successCallback()
              }
            })
        } else {
          this.$q.notify({
            color: 'negative',
            textColor: 'white',
            icon: 'check',
            message: this.$t('generals.complete_fields'),
            position: 'top'
          })
        }
      })
    },
    downloadInvoice (invoice) {
      Invoice.api().sendThroughFattureInCloud(invoice.company_id, invoice.id).then(() => {
        this.$q.notify({
          color: 'positive',
          textColor: 'white',
          icon: 'check',
          message: this.$t('app.subscriptions.invoice_sent'),
          position: 'top'
        })
      })
    },
    openCustomerPortal () {
      this.$q.loading.show()
      this.me.company.$getBillingPortalSession().then(data => {
        window.open(data.url, '_blank')
      })
        .catch(() => {
          this.$q.notify({
            color: 'warning',
            textColor: 'white',
            icon: 'warning',
            message: 'Si è verificato un errore, riprova',
            position: 'top'
          })
        })
        .finally(() => {
          this.$q.loading.hide()
        })
    }
    // async updatePaymentMethod () {
    //   this.$q.loading.show()
    //   const response = await this.stripeClient.handleCardSetup(
    //     this.intent ? this.intent.client_secret : null,
    //     this.cardElement,
    //     {
    //       payment_method_data: {
    //         billing_details: {
    //           name: this.cardHolderName
    //         }
    //       }
    //     }
    //   ).catch((error) => {
    //     console.log(error)
    //     this.$q.loading.hide()
    //   })

    //   await this.me.company.$updatePaymentMethod(response.setupIntent.payment_method)
    //     .then((response) => {
    //       this.user.company.card_brand = response.company.card_brand
    //       this.user.company.card_last_four = response.company.card_last_four
    //       this.$q.loading.hide()
    //     })
    // }
  },
  async mounted () {
    this.user = cloneDeep(this.me)
    /**
     * Stripe setup
     */
    await this.me.company.$getStripeSetupIntent().then((response) => {
      this.intent = response.data
    }).catch(e => console.error(e))

    await this.$stripe()

    this.stripeClient = window.Stripe(process.env.STRIPE_KEY)

    this.cardElement = await this.stripeClient.elements().create('card', { hidePostalCode: true })

    this.cardElement.mount('#card-element')

    if (!this.user.company.sdi) {
      this.user.company.sdi = '0000000'
    }
    if (this.user.company_id) {
      await Invoice.api().get(`/api/companies/${this.user.company_id}/invoices`)
    }
  }
}
</script>

<style>
    /**
     * The CSS shown here will not be introduced in the Quickstart guide, but shows
     * how you can use CSS to style your Element's container.
     */
    .StripeElement {
        box-sizing: border-box;

        height: 40px;

        padding: 10px 12px;

        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>
